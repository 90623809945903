import '../../App.css';
import './style.css'
import './responsive.css'

import c1 from "./assets/images/client/c1.jpg"
import c2 from "./assets/images/client/c2.jpg"
import c3 from "./assets/images/client/c3.jpg"
import c4 from "./assets/images/client/c4.jpg"
import c5 from "./assets/images/client/c5.jpg"
import c6 from "./assets/images/client/c6.jpg"


import React, { useEffect,useState } from "react";
import "../Login/AuthForm.css";
import GoogleLogo from "../../Assets/Image/google.svg";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { onAuthStateChanged, getAuth, GoogleAuthProvider, signInWithEmailAndPassword, signInWithPopup } from "firebase/auth";
import app from "../../firebase.init";


function Landing() {
    const auth = getAuth(app);
    const navigate = useNavigate();

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
          
            if (user) {
                // const uid = user.uid;
                setUser(user);
             
            } else {
                setUser({});
            }
        });
    }, [])
    const [user, setUser] = useState({});

    useEffect(() => {
        const checkUser = async () => {
            onAuthStateChanged(auth, async (user) => {
                if (user && user.uid) {
                    (navigate('/'))
                    await toast.success("You are already logged in", { id: 'ok' })
                }
            });
        };

        checkUser();
    }, []);
    const googleProvider = new GoogleAuthProvider();
    const googleAuth = () => {

        signInWithPopup(auth, googleProvider)
            .then((result) => {
                const user = result.user
                navigate('/travel');
                toast.success("You are logged in", { id: 'ok' })
            }).catch((error) => {
                const errorMessage = error.message;
            });
    }

    const handleFormLogin = (event) => {
        event.preventDefault();

        const email = event.target.email.value;
        const password = event.target.password.value;


        signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                // Signed in 
                const user = userCredential.user;
                navigate('/travel')
                toast.success("You are logged in", { id: 'ok' })
            })
            .catch((error) => {
                // const errorCode = error.code;
                const errorMessage = error.message;
                if (errorMessage.includes("Error (auth/wrong-password)")) {
                    toast.error("Email or password is wrong", { id: 'error' })
                }
                else if (errorMessage.includes("Error (auth/user-not-found)")) {
                    toast.error("User not found", { id: 'error' })
                }

                console.log(errorMessage);
            });
    }

    return (
        <>
            <section className='about-us'>
                <div className="container">
                    <div className="about-us-content">
                        <div className="row">
                            <div className="col-md-6 col-lg-6 col-xl-6 col-12">
                                <div className="single-about-us">
                                    <div className="about-us-txt">
                                        <h4>
                                            Plan your trip in seconds with a detailed itinerary at your fingertips.

                                        </h4>
                                    </div>
                                </div>
                            </div>
                            {user?.uid ?
                              <div className="col-md-6 col-lg-6 col-xl-6 col-12"></div>

                                : 
                                <div className='col-md-6 col-lg-6 col-xl-6 col-12'  style={{zIndex:"100000"}}>                     
                                <div className='auth-form-container'>
                                    <div className='auth-form'>
                                        <h1>Login</h1>
                                        <form onSubmit={handleFormLogin}>
                                            <div className='input-field'>
                                                <label htmlFor='email'>Email</label>
                                                <div className='input-wrapper'>
                                                    <input type='text' name='email' id='email' />
                                                </div>
                                            </div>
                                            <div className='input-field'>
                                                <label htmlFor='password'>Password</label>
                                                <div className='input-wrapper'>
                                                    <input type='password' name='password' id='password' />
                                                </div>
                                            </div>
                                            <p className='redirect pull-right'>
                                                <span onClick={() => navigate("/forgotpassword")}>Forgot Password?</span>
                                            </p>
                                            <button type='submit' className='auth-form-submit'>
                                                Login
                                            </button>
                                        </form>
                                        <p className='redirect'>
                                            New User?{" "}
                                            <span onClick={() => navigate("/signup")}>Create New Account</span>
                                        </p>
    
                                        <div className='horizontal-divider'>
                                            <div className='line-left' />
                                            <p>or</p>
                                            <div className='line-right' />
                                        </div>
                                        <div className='input-wrapper'>
                                            <button className='google-auth' onClick={googleAuth}>
                                                <img src={GoogleLogo} alt='' />
                                                <p> Continue with Google </p>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                </div>
                            }
                          
                        </div>
                    </div>
                </div>
            </section>


            <section className='container-fluid'>
                <div className="gallary-header text-center">
                    <h2>
                        Clients Reviews
                    </h2>

                </div>

                <div className='row d-flex justify-content-center'>
                    <div className='col-md-4 col-lg-4 col-xl-4 col-sm-6 col-12'>
                        <div className="">
                            <div className="home1-testm-single text-center">
                                <div className="home1-testm-img">
                                    <img src={c1} alt="img" />
                                </div>
                                <div className="home1-testm-txt">
                                    <p>
                                        I used to spend hours researching and planning my trips, but with this itinerary planner, everything was done for me in seconds! It saved me so much time and hassle, and my trip was amazing. Thank you!
                                    </p>
                                    <h3>
                                        <a href="#">
                                            kevin watson
                                        </a>
                                    </h3>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className='col-md-4 col-lg-4 col-xl-4 col-sm-6 col-12'>
                        <div className="">
                            <div className="home1-testm-single text-center">
                                <div className="home1-testm-img">
                                    <img src={c2} alt="img" />
                                </div>
                                <div className="home1-testm-txt">
                                    <p>
                                        I was skeptical at first about using an automatic itinerary planner, but I was pleasantly surprised by how accurate and customized my itinerary was. I loved all the suggested activities and restaurants, and I would definitely use this service again.
                                    </p>
                                    <h3>
                                        <a href="#">
                                        Lily
                                        </a>
                                    </h3>
                                </div>
                            </div>
                        </div>


                    </div>
                    <div className='col-md-4 col-lg-4 col-xl-4 col-sm-6 col-12'>
                        <div className="">
                            <div className="home1-testm-single text-center">
                                <div className="home1-testm-img">
                                    <img src={c3} alt="img" />
                                </div>
                                <div className="home1-testm-txt">
                                    <p>
                                        As a busy professional, I don't have time to plan my trips in detail. This software was a game-changer for me - it generated a comprehensive itinerary for my entire trip, including flights, accommodations, and activities. It was so convenient and stress-free, and I highly recommend it to anyone who wants to travel hassle-free.
                                    </p>
                                    <h3>
                                        <a href="#">
                                            Marie kurie
                                        </a>
                                    </h3>
                                </div>
                            </div>
                        </div>


                    </div>




                </div>
                <div className='row d-flex justify-content-center mt-4'>
                    <div className='col-md-4 col-lg-4 col-xl-4 col-sm-6 col-12'>
                        <div className="">
                            <div className="home1-testm-single text-center">
                                <div className="home1-testm-img">
                                    <img src={c4} alt="img" />
                                </div>
                                <div className="home1-testm-txt">
                                    <p>
                                        It was like having a personal travel assistant at my fingertips. I will definitely use this service again!
                                    </p>
                                    <h3>
                                        <a href="#">
                                        Ashley
                                        </a>
                                    </h3>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className='col-md-4 col-lg-4 col-xl-4 col-sm-6 col-12'>
                        <div className="">
                            <div className="home1-testm-single text-center">
                                <div className="home1-testm-img">
                                    <img src={c5} alt="img" />
                                </div>
                                <div className="home1-testm-txt">
                                    <p>
                                        I was able to customize my itinerary to my preferences and the app even provided insider tips and hidden gems that made my trip unforgettable. Thank you so much!
                                    </p>
                                    <h3>
                                        <a href="#">
                                            Sam
                                        </a>
                                    </h3>

                                </div>
                            </div>
                        </div>


                    </div>
                    <div className='col-md-4 col-lg-4 col-xl-4 col-sm-6 col-12'>
                        <div className="">
                            <div className="home1-testm-single text-center">
                                <div className="home1-testm-img">
                                    <img src={c6} alt="img" />
                                </div>
                                <div className="home1-testm-txt">
                                    <p>
                                        It took care of all the logistics and allowed me to focus on enjoying my trip. I highly recommend it to anyone who wants to save time and have a stress-free travel experience.
                                    </p>
                                    <h3>
                                        <a href="#">
                                        Heather
                                        </a>
                                    </h3>
                                </div>
                            </div>
                        </div>


                    </div>




                </div>







            </section>

            <footer className="footer-copyright">
                <div className="container">

                    <hr></hr>
                    {/* <div className="foot-icons">
                        <ul className="footer-social-links list-inline list-unstyled d-flex align-items-center justify-content-center">
                            <li><a href="#" target="_blank" className="foot-icon-bg-1"><i className="fa fa-facebook"></i></a></li>
                            <li><a href="#" target="_blank" className="foot-icon-bg-2"><i className="fa fa-twitter"></i></a></li>
                            <li><a href="#" target="_blank" className="foot-icon-bg-3"><i className="fa fa-instagram"></i></a></li>
                        </ul>
                        <p>&copy; 2023 . All Right Reserved</p>

                    </div> */}

                </div>
            </footer>

        </>





    );
}

export default Landing;
