import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import app from "../../firebase.init";
import "./Home.css";
const Home = () => {
  const navigate = useNavigate();
  const auth = getAuth(app);
  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (!user) {
        navigate('/')
      }
    });
  }, [])
  const inputDays = useRef();
  const inputDestination = useRef();
  const inputBudget = useRef();
  const inputRestaurant = useRef();
  const inputThings = useRef();

  const [loading, setLoading] = useState(false);
  async function handleSend() {
    const days = inputDays.current.value;
    const destination = inputDestination.current.value;
    const budget = inputBudget.current.value ? inputBudget.current.value : "no specific budget"
    const restaurant = inputRestaurant.current.value ? inputRestaurant.current.value : "no specific restaurants"
    const things=inputThings.current.value ? inputThings.current.value : "no specific things";

    setLoading(true);

    if (days == "") {
      alert("Please input days")
      setLoading(false);
    }

    if (destination == "") {
      alert("Please input destination")
      setLoading(false);
    }

    if (days != "" && destination != "") {
      document.querySelector("#loader").style.visibility = "visible";
      document.querySelector("#magic").style.visibility = "visible";
      // workout plan
      var prompt = `
      Give me a ${days} day ${destination} itinerary in detail for a budget of ${budget} a day and include ${restaurant}. I will do these things ${things} during the trip. Also include cost incured for every event.
    `
      const DEFAULT_PARAMS = {
        "model": "gpt-3.5-turbo",
        "messages": [
          { role: "system", content: "Act as a travel itinerary planner. Create a detailed itinerary that includes transportation, accommodations, cost, and activities for each day of their trip. Make sure to recommend the best restaurants, local experiences." },
          { role: "user", content: prompt }
        ],
      }

      const params = { ...DEFAULT_PARAMS };
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + String(process.env.REACT_APP_OPEN_AI_KEY)
        },
        body: JSON.stringify(params)
      };
      const response = await fetch('https://api.openai.com/v1/chat/completions', requestOptions);
      const data = await response.json();
      var workoutPlan = data.choices[0].message.content
      document.getElementById("workout_result").value = workoutPlan
      setLoading(false);
      document.querySelector("#loader").style.visibility = "hidden";
      document.querySelector("#magic").style.visibility = "hidden";
    }
  };
  return (
    <>

      <div class="container-fluid mt-4">
        <div class="row">
          <div class="col-md-6 col-lg-6 col-xl-6 col-12">

            <div class="form-group mx-auto">
              <label for="days">Number of Days *</label>
              <input ref={inputDays} type="number" class="form-control valid" id="days" min="1" placeholder=""/>
            </div>
            <div class="form-group mx-auto mt-4">
              <label for="destination">Destination *</label>
              <input ref={inputDestination} type="text" class="form-control valid" id="destination" placeholder="San Francisco"/>
            </div>
            <div class="form-group mx-auto mt-4">
              <label for="budget">Budget a day (in $)</label>
              <input ref={inputBudget} type="number" class="form-control" id="budget" placeholder=""/>
            </div>
            <div class="form-group mx-auto mt-4">
              <label for="restaurant">Type of Restaurants or cuisine</label>
              <input ref={inputRestaurant} type="text" class="form-control" id="restaurant" placeholder="rooftop and vegan restaurants"/>
            </div>
            <div class="form-group mx-auto mt-4">
              <label for="restaurant">Things to do</label>
              <input ref={inputThings} type="text" class="form-control" id="things" placeholder="ie. hiking, water activities"/>
            </div>



            <div class="row">
              <div class="col-12 text-center mb-4">
                <button disabled={loading} class="btn btn-primary" onClick={handleSend}>
                  {/* <button class="btn" onClick={handleSend}> */}
                  Generate
                </button>
                <div id="loader" class="center"></div>
                <div id="magic"></div>
                {/* <button type="button" class="btn btn-primary" id="submitButton">Generate</button> */}
              </div>
            </div>

          </div>

          <div class="col-md-6 col-lg-6 col-xl-6 col-12">
              <div class="form-group mx-auto">
                <label for="">AI Generated Travel Itinerary
                </label>
                <textarea class="form-control" placeholder="" rows="20"
                  id="workout_result"></textarea>
              </div>
          </div>

        </div>
      </div>

    </>

  );
};

export default Home;
