import { initializeApp } from "firebase/app";
const firebaseConfig = {
  apiKey: "AIzaSyAwrkM41c7QEkvtIKu1w49dzY_J6-igA0Q",
  authDomain: "travel-itinerary-8a47b.firebaseapp.com",
  projectId: "travel-itinerary-8a47b",
  storageBucket: "travel-itinerary-8a47b.appspot.com",
  messagingSenderId: "401801613247",
  appId: "1:401801613247:web:20221703b2eefaea189b0a",
  measurementId: "G-CGN9TQ2G8V"
};


const app = initializeApp(firebaseConfig);


export default app;



