import "./App.css";
import { Routes, Route, Navigate } from "react-router-dom";

import Home from "./Components/Home/Home";
import Navbar from "./Components/Navbar/Navbar";
import Signup from "./Components/Signup/Signup";
import NotFound from "./Components/NotFound/NotFound";
import { Toaster } from 'react-hot-toast';
import ResetPassword from "./Components/ResetPassword/ResetPassword"
import ForgotPassword from "./Components/ForgotPassword/ForgotPassword";
import Landing from './Components/Landing/Landing'

function App() {
 
  return (
    <>

      <Navbar />
      <Toaster />
      <Routes>
        
        <Route path='/' element={<Landing />} />
        <Route path='/signup' element={<Signup />} />
        <Route path='/travel' element={<Home />} />
        <Route path='/forgotpassword' element={<ForgotPassword />} />
        <Route path='/reset-password' element={<ResetPassword />} />
        <Route path='*' element={<NotFound />} />
      </Routes>

    </>
  );
}

export default App;
